<template src="./ArrobaMedellinHorizontalCardAcademic.html"></template>

<script>
import courseService from "@/services/Courses";
import certificateService from "@/services/Certificate";
import historyService from "@/services/History";
import {mapGetters} from "vuex";
import likeCommentService from "@/services/LikeAndComment";
import { PROFILE_IMAGEN } from "@/utils/constants";
import CourseInfoModal from "@/components/CourseInfoModal/CourseInfoModal";

export default {
  name: "arroba-medellin-horizontal-card-academic",
  props: {
    courseId: {type: [Number, String]},
    img: {type: String},
    imgDescription: {type: String, default: "Imagen"},
    title: {type: String},
    subtitle: {type: [String, Object]},
    body: {type: String, required: false},
    typeCard: {type: Object},
    progress: {type: [Number, String]},
    buttonText: {type: String},
    buttonText2: {type: String},
    url: {type: String, default: ""},
  },
  components:{
    CourseInfoModal
  },
  data() {
    return {
      PROFILE_IMAGEN,
      comments: [],
      commentsResponse: [
        {
          id: "01c76ec6-601d-4a3c-a2e4-6da00aaedfe1",
          user: {
            id: "60316",
            name: "TeacherInvestigator",
            lastName: "TeacherInvestigator",
            document: "9009065986",
            email: "demo123@indielevelstudio.com.com",
            arrobaId: null,
            categoryCampus: null,
            cvUrl: null,
            arrobasNumber: 15507,
            city: "medellin",
            state: "antioquia",
            country: "colombia",
            institution: "Institución Universitaria Pascual Bravo",
            address: "ninguna",
            phone: "3185494578",
            cellPhone: "3185494578",
            genre: "M",
            photo: null,
            createdAt: "2022-06-01T14:50:55.074Z",
            updatedAt: "2022-09-30T01:46:06.000Z"
          },
          post: "53",
          typePost: "articlesBlog",
          comment: "Prueba con el rol profesor investigador de comentar una noticia que no la he publicado\nPrueba con el rol profesor investigador de comentar una noticia que no la he publicado\nPrueba con el rol profesor investigador de comentar una noticia que no la he publ"
        },
        {
          "id": "27f22aa4-cfbc-46b5-ae75-51d1c808f76a",
          "user": {
            "id": "60294",
            "name": "Student",
            "lastName": "Student",
            "document": "9009065986",
            "email": "karenlizethsilva@gmail.com",
            "arrobaId": null,
            "categoryCampus": null,
            "cvUrl": "https://firebasestorage.googleapis.com/v0/b/sapiencia-ciudadela.appspot.com/o/users%2Fcv%2FPRUEBA.pdf?alt=media&token=d4c55027-0d1d-45ea-893f-5b80c17e0dec",
            "arrobasNumber": 503552,
            "city": "Bello",
            "state": "Alagoas",
            "country": "Brazil",
            "institution": "Institución Universitaria Pascual Bravo",
            "address": "ninguna",
            "phone": "3185494578",
            "cellPhone": "3185494578",
            "genre": "M",
            "photo": "https://firebasestorage.googleapis.com/v0/b/sapiencia-ciudadela.appspot.com/o/users%2FprofilePhoto%2Fprofile_pic_60294?alt=media&token=5a659ea7-fe53-4850-b969-dd07da39eafc",
            "createdAt": "2022-04-04T17:43:49.132Z",
            "updatedAt": "2022-09-30T01:57:52.000Z"
          },
          "post": "53",
          "typePost": "articlesBlog",
          "comment": "Hey"
        },
        {
          "id": "7a65da5e-05e5-45f5-b5c3-8b463916786e",
          "user": {
            "id": "60316",
            "name": "TeacherInvestigator",
            "lastName": "TeacherInvestigator",
            "document": "9009065986",
            "email": "demo123@indielevelstudio.com.com",
            "arrobaId": null,
            "categoryCampus": null,
            "cvUrl": null,
            "arrobasNumber": 15507,
            "city": "medellin",
            "state": "antioquia",
            "country": "colombia",
            "institution": "Institución Universitaria Pascual Bravo",
            "address": "ninguna",
            "phone": "3185494578",
            "cellPhone": "3185494578",
            "genre": "M",
            "photo": null,
            "createdAt": "2022-06-01T14:50:55.074Z",
            "updatedAt": "2022-09-30T01:46:06.000Z"
          },
          "post": "53",
          "typePost": "articlesBlog",
          "comment": "Hola Hola esto es una prueba de los comentarios Hola Hola esto es una prueba de los comentariosHola Hola esto es una prueba de los comentariosHola Hola esto es una prueba de los comentariosHola Hola esto es una prueba de los comentariosHola Hola esto es u"
        },
        {
          "id": "89268dc2-5925-425f-8278-563d3f298647",
          "user": {
            "id": "60295",
            "name": "Teacher",
            "lastName": "Teacher",
            "document": "9009065986",
            "email": "masterundead01@gmail.com",
            "arrobaId": null,
            "categoryCampus": null,
            "cvUrl": null,
            "arrobasNumber": 300666,
            "city": "medellin",
            "state": "antioquia",
            "country": "colombia",
            "institution": "Institución Universitaria Pascual Bravo",
            "address": "ninguna",
            "phone": "3185494578",
            "cellPhone": "3185494578",
            "genre": "O",
            "photo": "https://firebasestorage.googleapis.com/v0/b/sapiencia-ciudadela.appspot.com/o/users%2FprofilePhoto%2Fprofile_pic_60295?alt=media&token=4778c6de-236a-4232-b399-35edde092958",
            "createdAt": "2022-04-04T17:44:43.891Z",
            "updatedAt": "2022-09-30T01:57:54.000Z"
          },
          "post": "53",
          "typePost": "articlesBlog",
          "comment": "How"
        }
      ],
      ratingScore: 3,
      isCardModalActive: false,
      error: "",
      course: {},
      percent: {},
      courseUrl: {},
      programName: "",
      showCourseDetail: false,
      courseData: null,
      rating: {
        qualification: "",
        comment: "",
      },
    };
  },
  methods: {
    async getScore() {
      try {
        const data = {
          typePost: "course",
          post: this.courseData.curso.id,
        };
        const response =
          await likeCommentService.starsAndCommentsByPost(
            data
          );
        if (response.length > 0) this.ratingScore = response;
      } catch (e) {
        console.log(e)
      }
    },
    async getComments() {
      try {
        this.comments = await likeCommentService.getCourseComments(this.courseData.curso.id, "course");
        /*const data = {
          typePost: "course",
          postId: this.courseData.curso.id,
        };
        this.comments = await likeCommentService.getAnnouncementComments(data);*/
      } catch (e) {
        console.log('error', e);
      }
    },
    async pushButton(courseCode) {
      try {
        if (courseCode) {
          this.courseData = await courseService.getCourse(courseCode);
          this.showCourseDetail = true;
          await this.getScore();
          await this.getComments();

          console.log(this.showCourseDetail, this.showCourseDetail);
          /*if (response.message) {
            this.error = response.message;
            this.isCardModalActive = true;
            return false;
          }
          const { course, percent, url, programName } = response;

          this.course = course;
          this.percent = percent;
          this.courseUrl = url;
          this.programName = programName;

          this.isCardModalActive = true;*/
        }
      } catch (error) {
        this.error = error;
        this.isCardModalActive = true;
        console.error("Error on pushButton", error);
      }
    },
    async pushButtonModal(code) {
      try {
        const history = {
          title: `Ingreso a curso`,
          description: `Has entrado al curso ${this.course.nombre}`,
          url: "/academico",
          district: "Académico",
        };
        await historyService.createHistory(history);
        const url = await courseService.getUrlCourseMoodle(code);
        if (url.length) {
          window.open(url);
        }
      } catch (error) {
        this.error = error;
        console.error("Error on pushButtonModal", error);
      }
    },
    async pushButtonCertificate(course) {
      try {
        const params = {
          categoryId: course.categoria_id,
          title: course.nombre,
          codeCourse: `${course.codigo}`,
        };
        await certificateService.createCertificate(params);
        this.$router.push({path: "perfil"}).catch(() => {
        });
      } catch (error) {
        console.error("Error on pushButtonCertificate", error);
      }
    },
    imageCourse(image) {
      if (image) {
        if (image.substring(0, 4) === "http") {
          return image;
        }
        return process.env.VUE_APP_ARROBA + "/images/cursos/" + image;
      }
      return null;
    },
    async commentsRating() {
      try {
        this.rating.idCourse = Number(this.courseId);
        this.rating.idUser = Number(this.user.id);
        this.rating.qualification = Number(this.rating.qualification);
        await courseService.qualificationCourses(this.rating);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Calificación recibida",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        console.error("Error grading the course", error);
      }
    },
  },
  mounted() {
    this.getComments();
  },
  computed: {
    disableButton() {
      return this.url.length < 1;
    },
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style
  lang="scss"
  scoped
  src="./ArrobaMedellinHorizontalCardAcademic.scss"
></style>
