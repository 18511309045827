<template src="./CourseInfoModal.html"></template>

<script>
import courseService from "@/services/Courses";
import { mapGetters } from "vuex";
import likeCommentService from "@/services/LikeAndComment";
import { PROFILE_IMAGEN } from "@/utils/constants";
import coursesService from "@/services/Courses";
import certificateTemplateService from "@/services/CertificateTemplate";
import notificationsService from "../../services/Notifications";

export default {
  name: "CourseInfoModal",
  props: {
    courseId: { type: [String, Number] },
    showCourseDetail: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    courseImage: { type: String },
  },
  data() {
    return {
      courseProgress: { percent: { progress: 0 }, message: "" },
      isScoreModalActive: false,
      newRatingScore: null,
      PROFILE_IMAGEN,
      courseData: null,
      comments: [],
      newComment: null,
      courseCertificateData: null,
      ratingScore: null,
      isCardModalActive: false,
      showCourseDetailFlag: this.showCourseDetail
        ? this.showCourseDetail
        : false,
      rating: {
        qualification: "",
        comment: "",
      },
      role: "",
      signedUp: false,
      dataStructure: null,
      courseProgressUrl: "",
      stateButton: "notForm",
      modalShareCourses: false,
      actualCourseProgress: 0,
    };
  },
  mounted() {
    this.preEnrollSend(this.courseId);
  },
  methods: {
    async preEnrollSend(code) {
      try {
        this.dataStructure = await coursesService.getFormPreEnrollInformation(
          code
        );
      } catch (e) {
        this.stateButton = "notForm";
        console.log("Error on getCourseFormInformation", e);
      }
      if (this.dataStructure) {
        try {
          const validatePreEnrollment =
            await coursesService.validatePreEnrollment({
              userId: this.$store.state.User.user.id,
              codeCourse: code,
              formEnrollCourseId: this.dataStructure.id,
            });
          console.log(validatePreEnrollment);
          if (validatePreEnrollment) {
            this.stateButton = "register";
          } else {
            this.stateButton = "allReadyExist";
          }
        } catch (error) {
          console.log(error);
          this.stateButton = "allReadyExist";
        }
      }
    },
    async getScore() {
      this.ratingScore = [{ starsNumber: 0 }];
      try {
        const data = {
          typePost: "course",
          post: this.courseData.curso.id,
        };
        const response = await likeCommentService.starsAndCommentsByPost(data);
        if (response.length && response[0].starsNumber !== null)
          this.ratingScore = response;
      } catch (e) {
        console.log(e);
      }
    },
    async getComments() {
      try {
        this.comments = await likeCommentService.getCourseComments(
          this.courseData.curso.id,
          "course"
        );
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCourseProgress() {
      try {
        this.courseProgress = await coursesService.getCourseProgress(
          this.courseData.curso.codigo
        );
        if (
          this.courseProgress.message === "No se encontro el curso en moodle"
        ) {
          this.actualCourseProgress = 0;
        } else if (
          this.courseProgress.message ===
          "No se encontro ningun curso en moodle"
        ) {
          this.actualCourseProgress = 0;
        } else if (this.courseProgress.message === "No estas en este curso") {
          this.signedUp = true;
        } else {
          this.actualCourseProgress = this.courseProgress.percent.progress;
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCourseUrl() {
      try {
        this.courseProgressUrl = await coursesService.getCourseUrl(
          this.courseData.curso.codigo
        );
        window.open(this.courseProgressUrl, "_blank");
      } catch (e) {
        console.log("error", e);
      }
    },
    async rateCourse() {
      try {
        const data = {
          post: this.courseData.curso.id.toString(),
          typePost: "course",
          starsNumber: this.newRatingScore,
        };
        await likeCommentService.commentAndRate(data);
        this.isScoreModalActive = false;
        await this.getScore();
      } catch (e) {
        console.log("error", e);
      }
    },
    async sendNewComment() {
      try {
        const data = {
          post: this.courseData.curso.id.toString(),
          typePost: "course",
          commentText: this.newComment,
        };
        this.comments = await likeCommentService.createComment(data);
        this.newComment = null;
        await this.getComments();
      } catch (e) {
        console.log("error", e);
      }
    },
    async getCourseData() {
      try {
        if (this.courseId) {
          this.courseData = await courseService.getCourse(this.courseId);
          this.showCourseDetailFlag = true;
          await this.getScore();
          await this.getComments();
          if (this.editable) {
            await this.getCourseProgress();
          }
        }
      } catch (error) {
        console.error("Error on pushButton", error);
      }
    },
    async getCertificateByCourse() {
      try {
        this.courseCertificateData =
          await certificateTemplateService.getCertificateTemplateByCourse(
            this.courseData.curso.id
          );
        await this.generateCertificateByCourse();
      } catch (e) {
        console.log("e", e);
      }
    },
    async generateCertificateByCourse() {
      try {
        const data = {
          certificateTemplateId:
            this.courseCertificateData.certificateTemplate.id,
          title: this.courseCertificateData.certificateTemplate.name,
          codeCourse: this.courseId.toString(),
          token: this.$store.state.User.token,
        };
        this.courseCertificateData = await coursesService.generateCertificate(
          data
        );
        window.open(this.courseCertificateData.url, "_blank");
      } catch (e) {
        console.log("e", e);
      }
    },
    close() {
      this.$emit("closeModal");
    },
    async share() {
      try {
        const infoShare = {
          title: "Te han compartido un curso",
          body: this.courseData.curso.nombre,
          // TO DO servicio mal estructurado no deberia enviarse el id de la notificacion quemado
          typeId: "5935bfc3-dfe5-457e-b2c1-729af44c1b82",
        };
        await notificationsService.shareAnnouncement(infoShare);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Curso compartido",
          position: "is-bottom",
          type: "is-success",
        });
        this.modalShareCourses = false;
      } catch (error) {
        console.error(error);
      }
    },
    showShareModal() {
      this.modalShareCourses = true;
    },
  },
  created() {
    this.role = this.user.role.name;
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    roleValidationAdmin() {
      return ["Administrator"].some((a) => a === this.role);
    },
  },
  watch: {
    showCourseDetail(newVal) {
      this.courseProgress = { percent: { progress: 0 }, message: "" };
      if (newVal) {
        this.getCourseData();
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
  src="./CourseInfoModal.scss"
>
</style>
